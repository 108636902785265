<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <strong>Inspection</strong>
          </CCardHeader>
          <CRow>
            <CCol md="8">
              <CCardBody>
                <CForm>
                  <CInput
                    label="Details *"
                    v-model="qmsInspection.details"
                    horizontal
                    :disabled="mode.viewMode"
                    :invalid-feedback="'Please Enter the Details'"
                    @blur="$v.qmsInspection.details.$touch()"
                    :is-valid="
                      $v.qmsInspection.details.$dirty
                        ? !$v.qmsInspection.details.$error
                        : null
                    "
                  />
                  <div class="form-group row">
                    <label
                      for="example-text-input text-right"
                      class="col-sm-3 col-form-label"
                    >
                      Type of FCM
                    </label>
                    <div class="col-sm-9">
                      <select
                        class="form-control"
                        v-model="qmsInspection.fcm"
                        :disabled="mode.viewMode"
                      >
                        <option disabled value="">--Select One Option--</option>
                        <option value="1">Non-FCM</option>
                        <option value="2">FCM</option>
                      </select>
                    </div>
                  </div>
                  <CInput
                    label="Groups *"
                    v-model="qmsInspection.groups"
                    horizontal
                    :disabled="mode.viewMode"
                    :invalid-feedback="'Please Enter the Groups'"
                    @blur="$v.qmsInspection.groups.$touch()"
                    :is-valid="
                      $v.qmsInspection.groups.$dirty
                        ? !$v.qmsInspection.groups.$error
                        : null
                    "
                  />
                </CForm>
              </CCardBody>
            </CCol>
          </CRow>

          <CCardFooter align="right">
            <CButton
              v-if="mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="primary"
              @click="editMode()"
            >
              <CIcon name="cil-pencil" /> Edit
            </CButton>

            <CButton
              v-if="!mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="success"
              @click="save()"
            >
              <CIcon name="cil-check-circle" /> Save
            </CButton>

            <CButton
              class="mr-2"
              type="Cancel"
              size="sm"
              color="danger"
              @click="cancel()"
            >
              <CIcon name="cil-ban" /> Cancel
            </CButton>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Vue from "vue";
import datetime from "vuejs-datetimepicker";
import {
  required,
  minLength,
  maxLength,
  url,
  requiredIf,
} from "vuelidate/lib/validators";
export default {
  name: "QMSInspection",
  data() {
    return {
      mode: {
        newMode: false,
        editMode: false,
        viewMode: false,
      },
      qmsInspectionId: "",
      qmsModelId: this.$route.params.qmsModelId,
      qmsInspection: {
        qmsInspectionId: "0",
        details: "",
        groups: "",
        fcm: "",
        qmsModelId: this.$route.params.qmsModelId,
      },

      horizontal: { label: "col-3", input: "col-9" },
    };
  },

  validations: {
    qmsInspection: {
      details: {
        required,
      },
      groups: {
        required,
      },
    },
  },
  created() {
    if (this.$route.params.mode == "new") {
      this.mode.newMode = true;
      this.mode.viewMode = false;
      this.mode.editMode = false;
      this.qmsModelId = this.$route.params.qmsModelId;
    }
    if (this.$route.params.mode == "view") {
      this.mode.viewMode = true;
      this.qmsInspectionId = this.$route.params.qmsInspectionId;
      this.getInspection();
    }
    if (this.$route.params.mode == "edit") {
      this.mode.editMode = true;
      this.qmsModelId = this.$route.params.qmsModelId;
      this.qmsInspectionId = this.$route.params.qmsInspectionId;
      this.getInspection();
    }
  },
  components: { datetime },
  methods: {
    editMode() {
      this.mode.editMode = true;
      this.mode.viewMode = false;
    },
    cancel() {
      //this.$router.push({ name: 'QMSModels' })
      window.history.back();
    },

    save() {
      this.$v.qmsInspection.$touch();
      if (this.$v.qmsInspection.$invalid) {
        console.log("form is invalid", JSON.stringify(this.$v.qmsInspection));
      } else {
        // fetch(
        //   process.env.VUE_APP_API_HOST +
        //     "/emapp/web/secure/save/qms/Inspection",
        //   {
        //     method: "POST",
        //     headers: {
        //       "Content-type": "application/json",
        //     },
        //     body: JSON.stringify(this.qmsInspection),
        //   }
        // )

        this.apiPostSecure(
          process.env.VUE_APP_API_HOST +
            "/emapp/web/secure/save/qms/Inspection",
          this.qmsInspection
        ).then((qmsInspection) => {
          console.log(JSON.stringify(qmsInspection));
          //this.$router.push({ name: "QMSModels" });
          window.history.back();
        });
      }
    },
    validator(val) {
      return val ? val.length >= 4 : false;
    },

    getInspection() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/get/qms/inspection/" +
          this.qmsInspectionId
      )
        .then((response) => response.json())
        .then((data) => (this.qmsInspection = data));
    },
  },
  mounted() {
    //this. getRoles();
  },
};
</script>
